import { useQuery } from 'react-query';

import { User } from './domain';

import { minutes } from '../../../utils/dateTime';

import { fetchGet } from '../../common';
import { queryClient } from '../../../layouts/ThemeProvider';

export const userQueryKey = 'user';

export const useUserQuery = () => {
  const userQry = useQuery<User>(userQueryKey, () => fetchGet('/api/user'), {
    staleTime: minutes(10),
    onSuccess: (data) => {
      const token = localStorage.getItem('userLogin');

      if (data.username === 'ANONYMOUS' && token) {
        queryClient.setQueryData('user', (oldData: any) => oldData);
      }
    },
  });

  return {
    ...userQry,
    user: userQry.data,
  };
};
